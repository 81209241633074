import { Popover, PopoverProps } from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import { FC } from "react";
import {
  DueDatePicker,
  DueDatePickerProps,
} from "../DueDatePicker/DueDatePicker";

export interface DueDatePickerPopoverProps
  extends Omit<DueDatePickerProps, "onClose">,
    Pick<PopoverProps, "renderTrigger" | "placement"> {
  className?: string;
}

export const DueDatePickerPopover: FC<DueDatePickerPopoverProps> = ({
  className,
  placement,
  renderTrigger,
  ...props
}) => (
  <Popover
    renderTrigger={renderTrigger}
    placement={placement}
    className={cx("w-[343px] px-4 pt-4 pb-8", className)}
  >
    {({ onClose }) => <DueDatePicker onClose={onClose} {...props} />}
  </Popover>
);
