import {
  DragOverlay,
  defaultDropAnimationSideEffects,
  useDraggable,
} from "@dnd-kit/core";
import {
  InteractiveContainer,
  InteractiveContainerProps,
} from "@jugl-web/ui-components/cross-platform";
import { TaskCardPill } from "@jugl-web/ui-components/cross-platform/tasks/TaskCardPill";
import { TaskLabelContainer } from "@jugl-web/ui-components/cross-platform/tasks/TaskLabelGroup";
import { cx, useTranslations, useUniqueId } from "@jugl-web/utils";
import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import { FC, HTMLAttributes, ReactNode } from "react";
import { createPortal } from "react-dom";
import Highlighter from "react-highlight-words";
import { TaskCardDraggableData } from "../../types";
import { shortenTaskTitle } from "../../utils";
import { ReactComponent as BellIcon } from "./assets/bell.svg";
import { ReactComponent as CheckBlankIcon } from "./assets/check-blank.svg";
import { ReactComponent as ClockIcon } from "./assets/clock.svg";
import { ReactComponent as RecurringWarningIcon } from "./assets/recurring-warning.svg";
import { ReactComponent as RecurringIcon } from "./assets/recurring.svg";
import { ReactComponent as ScheduleMdIcon } from "./assets/schedule-md.svg";

interface TaskCardChecklist {
  completedItemsCount: number;
  totalItemsCount: number;
  isOverdue: boolean;
}

interface TaskCardTimeSpent {
  hours: number;
  minutes: number;
}

export interface TaskCardField {
  id: string;
  text: string;
  textColor: string;
  backgroundColor: string;
  startIcon?: ReactNode;
}

export interface TaskCardProps extends HTMLAttributes<HTMLDivElement> {
  checklist: TaskCardChecklist;
  dueDate: Date | null;
  fields: TaskCardField[];
  isCompleted: boolean;
  isOverdue: boolean;
  isUnread: boolean;
  taskId: string;
  timeSpent: TaskCardTimeSpent;
  title: string;
  columnId?: string;
  draggable?: boolean;
  highlightedText?: string;
  recurrence?: {
    isFutureTask: boolean;
    isPaused: boolean;
  };
}

export const TaskCard: FC<TaskCardProps> = (props) => {
  const {
    taskId,
    isUnread,
    title,
    dueDate,
    isOverdue,
    isCompleted,
    checklist,
    timeSpent,
    fields,
    highlightedText,
    className,
    style,
    draggable,
    columnId,
    recurrence,
    onClick,
    ...divProps
  } = props;

  const { t } = useTranslations();
  const { formatDateTimeLabel } = useFormattedDate();

  const uniqueId = useUniqueId();

  const isFutureTask = !!recurrence?.isFutureTask;

  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: `task-${taskId}-${uniqueId}`,
    disabled: !draggable,
    data: {
      type: "task",
      taskId,
      columnId,
      isFutureTask,
    } satisfies TaskCardDraggableData,
  });

  const hasTimeSpent = timeSpent.hours > 0 || timeSpent.minutes > 0;
  const shortenedTitle = shortenTaskTitle(title);

  return (
    <>
      {isDragging &&
        createPortal(
          <DragOverlay
            dropAnimation={{
              sideEffects: defaultDropAnimationSideEffects({
                styles: {
                  active: {
                    opacity: "1",
                  },
                },
              }),
            }}
          >
            <TaskCard
              {...props}
              draggable={false}
              className="border-primary-600 border-px rotate-2 border-dashed opacity-80"
            />
          </DragOverlay>,
          document.body
        )}
      <InteractiveContainer
        ref={setNodeRef}
        className={cx(
          "relative touch-pan-y overflow-hidden rounded-2xl bg-white px-4 pb-4 transition-shadow",
          {
            "shadow-task-card hover:shadow-task-card-hovered": !isDragging,
          },
          isUnread ? "pt-7" : "pt-4",
          className
        )}
        style={{ WebkitTapHighlightColor: "transparent", ...style }}
        onClick={onClick as InteractiveContainerProps["onClick"]}
        {...listeners}
        {...attributes}
        {...divProps}
      >
        {isDragging && <div className="absolute inset-0 z-20 bg-[#E7E9EA]" />}
        {isUnread && (
          <TaskLabelContainer className="bg-gradients-danger absolute right-0 top-0 rounded-bl-xl px-2">
            <BellIcon />
          </TaskLabelContainer>
        )}
        <div className="flex flex-col gap-1">
          <span className="text-dark break-words text-sm font-medium leading-3 tracking-[0.14px]">
            {highlightedText ? (
              <Highlighter
                autoEscape
                highlightClassName="text-primary bg-primary-50"
                highlightTag="span"
                textToHighlight={shortenedTitle}
                searchWords={[highlightedText]}
              />
            ) : (
              shortenedTitle
            )}
          </span>
          {dueDate && (
            <span
              className={cx(
                "leading-2 self-start text-xs tracking-[0.12px]",
                isOverdue
                  ? "text-tertiary-700 bg-tertiary-50 rounded-md py-1 px-2 font-medium"
                  : "text-[#828282]"
              )}
            >
              {formatDateTimeLabel(dueDate)}
            </span>
          )}
        </div>
        {(checklist.totalItemsCount > 0 || hasTimeSpent) && !isFutureTask && (
          <div className="my-2 flex items-center gap-2 py-2">
            {checklist.totalItemsCount > 0 && (
              <div className="flex items-center gap-2">
                <div
                  className={cx(
                    "flex items-center gap-2 font-medium",
                    checklist.isOverdue
                      ? "text-tertiary-700 bg-tertiary-50 rounded-md py-1 px-2"
                      : "text-grey"
                  )}
                >
                  <CheckBlankIcon
                    className={cx(
                      checklist.isOverdue
                        ? "text-gradients-danger"
                        : "text-grey-600"
                    )}
                  />
                  <span className="text-xs leading-[14px]">
                    {checklist.completedItemsCount}/{checklist.totalItemsCount}
                  </span>
                </div>
                <div className="bg-dark-100 relative h-2.5 w-20 overflow-hidden rounded-lg">
                  <div
                    className="bg-grey-400 absolute inset-y-0 left-0 rounded-lg"
                    style={{
                      width: `${
                        (checklist.totalItemsCount === 0
                          ? 0
                          : checklist.completedItemsCount /
                            checklist.totalItemsCount) * 100
                      }px`,
                    }}
                  />
                </div>
              </div>
            )}
            {checklist.totalItemsCount > 0 && hasTimeSpent && (
              <div className="bg-grey-200 h-6 w-px" />
            )}
            {hasTimeSpent && (
              <div className="flex items-center gap-1.5">
                <ClockIcon />
                <span className="text-grey text-xs font-medium">
                  {t(
                    {
                      id: "tasks-page.time-spent-on-task-duration",
                      defaultMessage: "{hours}h : {minutes}min",
                    },
                    { hours: timeSpent.hours, minutes: timeSpent.minutes }
                  )}
                </span>
              </div>
            )}
          </div>
        )}
        {(!!recurrence || fields.length > 0) && (
          <div className="mt-2 flex flex-wrap gap-2">
            {recurrence && (
              <>
                {recurrence.isFutureTask ? (
                  <TaskCardPill
                    label={t({
                      id: "tasks-page.scheduled",
                      defaultMessage: "Scheduled",
                    })}
                    textColor="#A24A0F"
                    backgroundColor="#FFF2E8"
                    startIcon={<ScheduleMdIcon />}
                  />
                ) : (
                  <TaskCardPill
                    label={t({
                      id: "tasks-page.recurring-task",
                      defaultMessage: "Recurring task",
                    })}
                    textColor="#9F2E29"
                    backgroundColor="#FAECEE"
                    startIcon={<RecurringIcon />}
                    endIcon={recurrence.isPaused && <RecurringWarningIcon />}
                  />
                )}
              </>
            )}
            {fields.map((field) => (
              <TaskCardPill
                key={field.id}
                label={field.text}
                backgroundColor={field.backgroundColor}
                textColor={field.textColor}
                startIcon={field.startIcon}
              />
            ))}
          </div>
        )}
      </InteractiveContainer>
    </>
  );
};
