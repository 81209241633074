import { useTaskStatuses } from "@jugl-web/domain-resources/tasks/hooks/useTaskStatuses";
import { TaskStatusDetails } from "@jugl-web/domain-resources/tasks/hooks/useDefaultTaskStatuses";
import { ListBoxItem } from "@jugl-web/ui-components/cross-platform/ListBox";
import { TaskCardPill } from "@jugl-web/ui-components/cross-platform/tasks/TaskCardPill";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { FC, useMemo } from "react";
import { TaskTableCellComponentProps } from "../../types";
import { TaskTableLayout } from "../TaskTableLayout";

export const StatusCell: FC<TaskTableCellComponentProps> = ({
  task,
  entityId,
  isFutureTask,
  permissions,
  cellProps,
  onUpdate,
}) => {
  const { allStatuses, getStatusDetailsById } = useTaskStatuses({ entityId });

  const statusesAsListItems = useMemo<ListBoxItem<TaskStatusDetails>[]>(
    () => allStatuses.map((status) => ({ id: status.id, value: status })),
    [allStatuses]
  );

  const statusLabel = getStatusDetailsById(task.status).label;
  const isEditable = permissions.canEditStatus && !isFutureTask;

  return (
    <ResourcePickerPopover
      placement="bottom"
      className="w-[375px]"
      renderTrigger={({ Trigger, triggerRef, isOpen }) => (
        <Trigger
          ref={triggerRef}
          as={TaskTableLayout.Cell}
          isFocused={isOpen}
          isHoverable
          isDisabled={!isEditable}
          {...cellProps}
        >
          <TaskCardPill label={statusLabel} />
        </Trigger>
      )}
      items={statusesAsListItems}
      selectionBehavior={{ mode: "single", canToggle: false }}
      hasSearch
      defaultSelectedIds={[task.status]}
      maxVisibleItems={5}
      itemSize="md"
      spaceBetweenItems="compact"
      renderLabel={(item) => item.value.label}
      onSelect={({ item, onClose }) => {
        onUpdate({ status: item.value.id });
        onClose();
      }}
    />
  );
};
