export type ChatMessageBodyToken = {
  type: "url" | "string" | "mention" | "userName";
  rawString?: string;
  humanizedString?: string;
  id: string;
  value?: string;
};

export enum ChatMessagePayloadAttachmentType {
  image = 1,
  audio = 2,
  video = 3,
  doc = 4,
  otherFile = 5,
  voice = 6,
  gif = 7,
  gifDirectUrl = 8,
}

export type DigestType =
  | "people"
  | "call"
  | "drive"
  | "chat"
  | "task"
  | "customer"
  | "task_reminder";

export type ChatMessagePayloadAttachment = {
  type: ChatMessagePayloadAttachmentType;
  uid: string;
  width?: number;
  height?: number;
  size: number;
  mimetype: string;
  name?: string;
  url?: string;
  caption?: string;
  _stream_url?: string;
  _preview_url?: string;
  _local_url?: string;
  _progress?: number;
  _error?: boolean;
};

export enum ChatType {
  chat = "chat",
  muc = "muc",
  entity_conversation = "entity_conversation",
  call = "call",
}

export enum ChatMessageType {
  chat = "chat",
  muc = "muc",
  recepit = "receipt",
  call = "call",
}

export enum PheonixPushAction {
  transport = "transport",
  read = "read",
  delivered = "delivered",
  update = "update",
  receipts = "receipts",
  bulk_receipts = "bulk_receipts",
  update_call = "update_call",
}

export enum ChatMessagePayloadAction {
  // Group updated
  updated = "updated",
  // User added to group
  added = "added",
  // User removed from group
  removed = "removed",
  // User left the group
  left = "left",
  deleted = "deleted",
  edited = "edited",
  action = "action",
}

export enum ChatMessagePayloadCallAction {
  call_declined = "call_declined",
  call_invite = "call_invite",
  call_duration = "call_duration",
  call_switch_voice_to_video = "call_switch_voice_to_video",
  call_hold = "call_hold",
  call_un_hold = "call_un_hold",
  call_answered = "call_answered",
  call_delivered = "call_delivered",
}

export enum ChatMessagePayloadActionFor {
  workspace = "workspace",
}

export enum ChatMessagePayloadType {
  message = "message",
  group_info = "group_info",
  typingIndicator = "typing_indicator",
  call = "call",
  digest = "digest",
  task_reminder = "task_reminder",
}

export enum ChatMessagePayloadPushType {
  silent = "silent",
  normal = "normal",
}

export type ChatMessagePayload = {
  version?: number;
  title?: string;
  body?: string | null;

  attachments?: ChatMessagePayloadAttachment[] | null;

  reply_attributes_map?: {
    reply_msg_id: string;
    reply_msg_body?: ChatMessagePayload["body"];
    reply_msg_user: string;
    reply_user_name?: string;
    reply_conference?: ChatMessagePayload["conference"];
    reply_attachments?: ChatMessagePayload["attachments"];
  } | null;

  participants?: string[];

  // === old

  push_type?: ChatMessagePayloadPushType;
  type?: ChatMessagePayloadType;
  group_id?: string;
  sender_name?: string;
  edited?: boolean;
  forwarded?: boolean;
  deleted?: boolean;
  msg_id_to_delete?: string;
  source_msg_id?: string;
  attributes_map?: {
    mentions?: string;
  };

  // Digest
  digest?: {
    digest: string;
    module: DigestType;
  }[];
  task_reminder?: {
    due_at: string;
    id: string;
    name: string;
  };

  // Actions
  action?: ChatMessagePayloadAction;
  action_for?: ChatMessagePayloadActionFor | string;
  action_from?: string;
  workspace_id?: string;

  // Calls
  call_action?: ChatMessagePayloadCallAction;
  call_channel?: string;
  call_type?: string;

  conference?: {
    channel: string;
    name?: string;
    date?: string;
    time?: string;
  };
  duration?: string;

  // Custom
  client_msg_id?: string;
};

export type ChatMessage = {
  msg_id: string;
  msg_receipt_id: string;
  type: ChatMessageType;
  from: string;
  to: string;
  entity_id: string;
  timestamp: string;
  payload: ChatMessagePayload;
  is_read_by_self?: boolean;
  deleted: boolean | null;
  delivered: boolean | null;
  read: boolean | null;
  edited: boolean | null;
  forwarded: boolean | null;

  reactions?: { user_id: string; reaction: string }[];

  _pending?: boolean;
  _error?: boolean;
};

export type SearchChatMessage = {
  body: string;
  caption: string;
  created_at: string;
  file_name: string;
  from: string;
  msg_id: string;
  msg_receipt_id: string;
};

export type ArchivedChatMessage = {
  entity_id: string;
  from: string;
  to: string;
  type: ChatMessageType;
  meta: unknown;
  msg_id: string;
  payload: ChatMessage;
};

export type ChatHistoryItem = {
  conversation: {
    id: string;
    img: string | null;
    title: string;
    type: ChatType;
    user_count?: number;
    timestamp: string;
  } | null;
  from: string;
  msg_id: string;
  deleted: boolean;
  msg_receipt_id: string;
  payload: ChatMessagePayload;
  timestamp: string;
  to: string;
  unread_count: {
    first_item: {
      created_at: string;
      msg_id: string;
    };
    unread_count: number;
  } | null;
};

export type Chat = {
  id: string;
  name: string;
  type: ChatType;
  img?: string;
  lastMessage?: ChatMessage;
  messagesCount?: number;
  selfChat?: boolean;
  unreadState?: ChatHistoryItem["unread_count"];
};

export interface SendMessagePheonixResponse {
  client_msg_id: string;
  msg_id: string;
  result: string;
  timestamp: string;
}

export enum ChatCallType {
  audio = "audio",
  video = "video",
}
export enum ScenarioCallType {
  conference = "conference",
  call = "call",
}

export interface ChatItem {
  id?: string;
  img?: null | string;
  title?: string;
  type: ChatType;
  isSelf?: boolean;
  unreadCount?: number;
  firstUnreadMessage?: {
    msgId: string;
    createdAt: string;
  };
  lastReadMessage?: {
    msgId: string;
    createdAt: string;
  };
  lastMessage?: {
    msgId: string;
    from: string;
    message: ChatMessage["payload"]["body"];
    tokenizedMessage: ChatMessageBodyToken[];
    timestamp: string;
    attachments?: ChatMessage["payload"]["attachments"];
    deleted?: boolean;
  };
  deleted?: boolean;
}

export enum ChatMessageActionType {
  forward = "forward",
  edit = "edit",
  reply = "reply",
  delete = "delete",
}

export interface ChatMessageAction {
  type: ChatMessageActionType;
  message: ChatMessage;
}

export interface BulkReceiptSentResponse {
  bulk: boolean;
  chat_id: string;
  chat_type: ChatType;
  count: number;
  entity_id: string;
  from: string;
  msg_id: string;
  msg_timestamp: string;
  msg_receipt_id: string;
  timestamp: string;
  type: string;
}

export type MessagesApiFetchParams = {
  entity_id: string;
  time?: string;
  limit: number;
  chat_id: string;
  type?: ChatType;
  filter?: string;
  attachment_type?: ChatMessagePayloadAttachmentType;
};

export type CallListPayload = {
  body: string | null | undefined;
  call_action: ChatMessagePayloadCallAction;
  call_channel: string;
  call_type: string;
  duration: string;
  push_type: ChatMessagePayloadPushType;
  sender_name: string;
  title: string;
  type: "call";
  version: number;
};
export type CallListHistoryItem = {
  from: string;
  msg_id: string;
  msg_receipt_id: string;
  payload: CallListPayload;
  timestamp: string;
  to: string;
};
