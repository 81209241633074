import {
  Popover,
  TaskPropertyButton,
} from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import { FC } from "react";
import { ReactComponent as RepeatIcon } from "../../assets/repeat.svg";
import { FieldComponentProps } from "../../types";
import { RecurrencePopoverContent } from "./components/RecurrencePopoverContent";
import { useTaskRecurrence } from "./hooks/useTaskRecurrence";

export const RecurrenceField: FC<FieldComponentProps<"recurrence">> = ({
  state,
  userTimezone,
  isReadonly,
  onChange,
}) => {
  const { getButtonLabel } = useTaskRecurrence();

  const hasRecurrence = !!state;
  const isFieldVisible = hasRecurrence || !isReadonly;

  if (!isFieldVisible) {
    return null;
  }

  return (
    <Popover
      placement="bottom-start"
      className="min-w-[300px] px-3 py-2"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          as={TaskPropertyButton}
          ref={triggerRef}
          isDisabled={isReadonly}
          className={cx(hasRecurrence && "bg-primary-50 text-primary-900")}
          startIcon={
            <RepeatIcon className={cx(hasRecurrence && "text-primary")} />
          }
        >
          {getButtonLabel(state)}
        </Trigger>
      )}
    >
      {({ onClose }) => (
        <RecurrencePopoverContent
          state={state}
          userTimezone={userTimezone}
          onChange={(s) => onChange?.(s)}
          onClose={onClose}
        />
      )}
    </Popover>
  );
};
