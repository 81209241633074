import {
  flip,
  offset,
  Placement,
  shift,
  useFloating,
} from "@floating-ui/react-dom";
import {
  Popover as HeadlessUIPopover,
  PopoverBackdrop as HeadlessUIPopoverBackdrop,
  PopoverButton as HeadlessUIPopoverButton,
  PopoverPanel as HeadlessUIPopoverPanel,
  Portal,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { assignRefs, cx, useResizeObserver } from "@jugl-web/utils";
import {
  ComponentLifecycleListener,
  ComponentLifecycleListenerProps,
} from "@jugl-web/utils/utils/ComponentLifecycleListener";
import {
  CSSProperties,
  forwardRef,
  Fragment,
  MouseEvent,
  ReactNode,
  Ref,
  useMemo,
} from "react";

interface RenderTriggerProps {
  Trigger: typeof HeadlessUIPopoverButton;
  triggerRef: Ref<HTMLButtonElement>;
  isOpen: boolean;
  onClose: () => void;
}

interface ChildrenProps {
  isOpen: boolean;
  onClose: () => void;
}

export interface PopoverProps extends ComponentLifecycleListenerProps {
  children: ReactNode | ((props: ChildrenProps) => ReactNode);
  placement: Placement;
  hasBackdrop?: boolean;
  adjustToTriggerWidth?: boolean;
  isDisabled?: boolean;
  className?: string;
  style?: CSSProperties;
  isStatic?: boolean;
  renderTrigger: (props: RenderTriggerProps) => JSX.Element;
  onOverlayClickCapture?: (event: MouseEvent) => void;
}

export const Popover = forwardRef<HTMLDivElement, PopoverProps>(
  (
    {
      children,
      placement,
      hasBackdrop,
      adjustToTriggerWidth,
      isDisabled,
      className,
      style,
      isStatic,
      renderTrigger,
      onOverlayClickCapture,
      onMount,
      onUnmount,
    },
    forwardedRef
  ) => {
    const { refs, floatingStyles, elements, update } =
      useFloating<HTMLButtonElement>({
        placement,
        middleware: [
          flip({
            fallbackStrategy: "bestFit",
            fallbackAxisSideDirection: "start",
          }),
          shift(),
          offset({ mainAxis: 4 }),
        ],
      });

    const { ref: panelRef } = useResizeObserver({ onResize: update });

    const popoverWidth = useMemo<CSSProperties["width"]>(() => {
      if (!adjustToTriggerWidth || !elements.reference) {
        return undefined;
      }

      return elements.reference.getBoundingClientRect().width;
    }, [adjustToTriggerWidth, elements.reference]);

    return (
      <HeadlessUIPopover as={Fragment}>
        {({ open, close }) => (
          <>
            {renderTrigger({
              Trigger: HeadlessUIPopover.Button,
              triggerRef: refs.setReference,
              isOpen: open,
              onClose: close,
            })}

            {!isDisabled && (
              <Portal>
                <Transition as={Fragment}>
                  <TransitionChild
                    as={Fragment}
                    enter="transition-opacity"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <HeadlessUIPopoverPanel
                      as="div"
                      ref={assignRefs([
                        refs.setFloating,
                        forwardedRef,
                        panelRef,
                      ])}
                      {...(isStatic
                        ? {
                            static: true,
                          }
                        : // `unmount` has to be `true` to make the ComponentLifecycleListener work
                          { unmount: true })}
                      className={cx(
                        "absolute z-[100] rounded-lg bg-white",
                        className
                      )}
                      style={{
                        boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.12)",
                        width: popoverWidth,
                        ...floatingStyles,
                        ...style,
                      }}
                      onClick={(event) => event.stopPropagation()}
                    >
                      {typeof children === "function"
                        ? children({ isOpen: open, onClose: close })
                        : children}
                      <ComponentLifecycleListener
                        onMount={onMount}
                        onUnmount={onUnmount}
                      />
                    </HeadlessUIPopoverPanel>
                  </TransitionChild>
                  {hasBackdrop && (
                    <TransitionChild
                      as={Fragment}
                      enter="transition-opacity"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition-opacity"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <HeadlessUIPopoverBackdrop
                        className="fixed inset-0 z-50 bg-[rgba(33,58,72,0.38)] backdrop-blur-[1px]"
                        style={{ backgroundColor: "rgba(33, 58, 72, 0.38)" }}
                        onClickCapture={onOverlayClickCapture}
                      />
                    </TransitionChild>
                  )}
                </Transition>
              </Portal>
            )}
          </>
        )}
      </HeadlessUIPopover>
    );
  }
);
