import {
  TaskRecurrence,
  TaskRecurrenceIntervalEvery,
} from "@jugl-web/rest-api/tasks";
import { ListBox, ListBoxItem } from "@jugl-web/ui-components/cross-platform";
import { assert } from "@jugl-web/utils";
import { isEqual, pick } from "lodash";
import { FC, useMemo, useState } from "react";
import {
  RecurrenceListOptionDetails,
  useTaskRecurrence,
} from "../../hooks/useTaskRecurrence";
import { RecurrenceListOption } from "../../types";
import { RecurrenceCustomSettings } from "../RecurrenceCustomSettings";
import { ReactComponent as ChevronRightIcon } from "../../assets/chevron-right.svg";

type RecurrenceFieldPopoverView = "default-presets" | "custom-settings";

interface RecurrencePopoverContentProps {
  state: TaskRecurrence | null;
  userTimezone: string;
  onChange: (state: TaskRecurrence | null) => void;
  onClose: () => void;
}

export const RecurrencePopoverContent: FC<RecurrencePopoverContentProps> = ({
  state,
  userTimezone,
  onChange,
  onClose,
}) => {
  const [internalState, setInternalState] = useState<TaskRecurrence>(() => {
    if (!state) {
      return {
        tz: userTimezone,
        interval: {
          every: TaskRecurrenceIntervalEvery.day,
          freq: 1,
        },
        end_at: null,
      };
    }

    return state;
  });

  const hasRecurrence = !!state;

  const { getRecurrenceListOptionIdToDetailsMap } = useTaskRecurrence();

  const todaysRecurrenceListOptions = useMemo<
    ListBoxItem<RecurrenceListOptionDetails>[]
  >(() => {
    const recurrenceListOptionIdToDetailsMap =
      getRecurrenceListOptionIdToDetailsMap(new Date());

    return [
      {
        id: RecurrenceListOption.none,
        value: recurrenceListOptionIdToDetailsMap[RecurrenceListOption.none],
      },
      {
        id: RecurrenceListOption.daily,
        value: recurrenceListOptionIdToDetailsMap[RecurrenceListOption.daily],
      },
      {
        id: RecurrenceListOption.weeklyOnDay,
        value:
          recurrenceListOptionIdToDetailsMap[RecurrenceListOption.weeklyOnDay],
      },
      {
        id: RecurrenceListOption.monthlyOnWeekday,
        value:
          recurrenceListOptionIdToDetailsMap[
            RecurrenceListOption.monthlyOnWeekday
          ],
      },
      {
        id: RecurrenceListOption.yearlyOnDate,
        value:
          recurrenceListOptionIdToDetailsMap[RecurrenceListOption.yearlyOnDate],
      },
      {
        id: RecurrenceListOption.customSettings,
        value:
          recurrenceListOptionIdToDetailsMap[
            RecurrenceListOption.customSettings
          ],
      },
    ];
  }, [getRecurrenceListOptionIdToDetailsMap]);

  const selectedOptionId = useMemo<RecurrenceListOption>(() => {
    if (!hasRecurrence) {
      return RecurrenceListOption.none;
    }

    const matchingListOption = todaysRecurrenceListOptions.find((option) => {
      if (
        option.value.interval === undefined ||
        option.value.endAt === undefined
      ) {
        // means it is not a preset option
        return false;
      }

      const currentState = pick(state, ["interval", "end_at"]);

      const presetState: typeof currentState = {
        interval: option.value.interval,
        end_at: option.value.endAt,
      };

      return isEqual(currentState, presetState);
    });

    if (matchingListOption) {
      return matchingListOption.id as RecurrenceListOption;
    }

    return RecurrenceListOption.customSettings;
  }, [hasRecurrence, state, todaysRecurrenceListOptions]);

  const [view, setView] = useState<RecurrenceFieldPopoverView>(
    selectedOptionId === RecurrenceListOption.customSettings
      ? "custom-settings"
      : "default-presets"
  );

  const handleRecurrenceListOptionSelect = (
    item: ListBoxItem<RecurrenceListOptionDetails>,
    closePopover: () => void
  ) => {
    if (item.id === RecurrenceListOption.customSettings) {
      setView("custom-settings");
      return;
    }

    if (item.id === RecurrenceListOption.none) {
      onChange(null);
    } else {
      assert(!!item.value.interval, "At this point interval should be defined");

      onChange({
        tz: userTimezone,
        interval: item.value.interval,
        end_at: null,
      });
    }

    closePopover();
  };

  return (
    <>
      {view === "default-presets" ? (
        <ListBox
          items={todaysRecurrenceListOptions}
          selectionBehavior={{ mode: "single", canToggle: true }}
          onSelect={(item) => handleRecurrenceListOptionSelect(item, onClose)}
          defaultSelectedIds={[selectedOptionId]}
          renderLabel={(item) => item.value.label}
          renderEndIcon={(item) =>
            item.id === RecurrenceListOption.customSettings ? (
              <ChevronRightIcon />
            ) : null
          }
          itemSize="sm"
          spaceBetweenItems="compact"
        />
      ) : (
        <RecurrenceCustomSettings
          state={internalState}
          onChange={setInternalState}
          onSubmit={() => onChange(internalState)}
          onGoBack={() => setView("default-presets")}
          onClose={onClose}
        />
      )}
    </>
  );
};
